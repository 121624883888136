//! @ngInject
export function visitInstanceManualClockChangesModalCtrl(
  $scope,
  $uibModalInstance,
  changes,
  reasons,
  isHHAXContract,
  agencyMemberNamesDict
) {
  this.$onInit = function () {
    $scope.changes = changes;
    $scope.reasons = reasons;
    $scope.isHHAXContract = isHHAXContract;
   
    $scope.changes.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    for (const [index, change] of $scope.changes.entries()) {
      const reasonObj = $scope.reasons.find(({ id }) => id === change.predefinedAnswerId);
      change.reason = reasonObj ? reasonObj.text : "";
      if (changes[index - 1]) {
        if (changes[index - 1].clockInTime === changes[index].clockInTime) {
          changes[index].isSameClockInTime = true;
        }

        if (changes[index - 1].clockOutTime === changes[index].clockOutTime) {
          changes[index].isSameClockOutTime = true;
        }
      }

      change.agencyMemberFullName = agencyMemberNamesDict[change.createdBy];
    }
  };

  $scope.closeModal = function (reason) {
    $uibModalInstance.close(reason);
  };
};
