import angular from "angular";
import { ApiErrors } from "../../scripts/consts/apiErrors.const";
import { OfficeId } from "../../scripts/messages/ids";
import { Office } from "../../scripts/messages/office";
import {
  AgencyMemberCreateCustomPlanOfCareTypeForOfficesRequest,
  CustomPOCItemRequest,
  DefaultPOCTaskItems,
} from "../../scripts/messages/plan_of_care_type";
import { GeneralUtilsService } from "../../scripts/services/generalUtils";
import { PlanOfCareService } from "../../scripts/services/planOfCareService";
import "./custom-plan-of-care-type.component.scss";

const ITEM_ID_PREFIX = "9783";
export type CustomPOCItemItemState = "VALID" | "EDIT" | "ERROR";
export interface ExtendedCustomPOCItem extends CustomPOCItemRequest {
  state: CustomPOCItemItemState;
  id: string;
}
interface ExtendedCustomPlanOfCareTypeForOffices
  extends AgencyMemberCreateCustomPlanOfCareTypeForOfficesRequest {
  items: ExtendedCustomPOCItem[];
}

interface ResolveObject {
  offices: Office[];
  closeModal: () => void;
  handleSuccess: () => void;
}

interface CustomPlanOfCareTypeBindings {
  resolve: ResolveObject;
}

interface CustomPlanOfCareTypeCtrlComponentOptions extends angular.IComponentOptions {
  bindings: Record<keyof CustomPlanOfCareTypeBindings, string>;
  $name: string;
}

type TasksErrorType = "NO_TASKS" | "INCOMPLETE" | "UNIQUE_CODES" | "ORDER";
const TasksErrorTypes: Record<TasksErrorType, string> = {
  NO_TASKS: "POC requires at least 1 task",
  INCOMPLETE: "There are unsaved tasks",
  UNIQUE_CODES: "The same task code is used in more than 1 task",
  ORDER: "The same task order is used in more than 1 task",
};

interface EntityValidation {
  valid: boolean;
  name: "OFFICES" | "ITEMS";
  errorMessages: string[];
}

interface DataModelValidation {
  fields: EntityValidation[];
}

const customPOCTypeInitialState: ExtendedCustomPlanOfCareTypeForOffices = {
  items: [],
  officeIds: [],
  requirePatientSignature: true,
};

const customPOCTypeItemInitialState: ExtendedCustomPOCItem = {
  isActive: true,
  code: "",
  isPersonalCare: false,
  label: "",
  order: 1,
  section: "",
  type: "Task",
  state: "EDIT",
  id: `${ITEM_ID_PREFIX}`
};

//! @ngInject
class CustomPlanOfCareTypeCtrl implements ng.IComponentController, CustomPlanOfCareTypeBindings {
  // ---- bindings ----- //
  resolve!: ResolveObject;
  // ------------------- //
  private data: ExtendedCustomPlanOfCareTypeForOffices;
  private categoryInput = "";
  private isSubmitting = false;
  private isLoadingDefaultPOC = false;
  private categories: { id: number; label: string }[] = [];
  private selectedOffices: { id: number; label: string }[] = [];
  private defaultPOCCache: DefaultPOCTaskItems | null = null;
  private showTasksContent: boolean;
  private dataValidation: DataModelValidation = {
    fields: [
      { name: "OFFICES", valid: true, errorMessages: ["Please select desired office/s"] },
      { name: "ITEMS", valid: true, errorMessages: [TasksErrorTypes.NO_TASKS] },
    ],
  };
  constructor(
    public $scope: ng.IScope,
    private planOfCareService: PlanOfCareService,
    private apiErrors: ApiErrors,
    private toaster: toaster.IToasterService,
    private mfModal: any,
    private generalUtils: GeneralUtilsService
  ) {
    this.showTasksContent = true;
    this.data = angular.copy(customPOCTypeInitialState);
  }

  officeExtraSettings = {
    styleActive: true,
    smartButtonMaxItems: 3,
    enableSearch: true,
  };

  officesEvents = {
    onSelectionChanged: () => {
      this.data.officeIds = this.selectedOffices.map((o) => o.id as unknown as OfficeId);
    },
  };

  handleClickAddCategory = () => {
    if (this.categoryInput === "") return;
    const cIndex = this.categories.findIndex(
      (c) => c.label.toLocaleLowerCase() === this.categoryInput.toLocaleLowerCase()
    );
    if (cIndex > -1) {
      this.categoryInput = "";
      return this.toaster.pop("warning", "Category already exists");
    }

    this.categories.push({ id: this.categories.length + 1, label: this.categoryInput });
    this.categoryInput = "";
  };

  handleClickRemoveCategory = (categoryId: number) => {
    const categoryIndex = this.categories.findIndex(c => c.id === categoryId);
    if (categoryIndex === -1) {
      return this.toaster.pop("warning", "Failed to remove category");
    }

    const isUsedCategory = this.data.items.find(i => i.section === this.categories[categoryIndex].label)?.section;
    if (isUsedCategory) {
      return this.toaster.pop("warning", "The category is being used in one of the tasks");
    }

    this.categories.splice(categoryIndex, 1);
  };

  handleClickAddNewItem = () => {
    const newItem = angular.copy(customPOCTypeItemInitialState);
    newItem.id = `${ITEM_ID_PREFIX}-${this.data.items.length + 1}`;
    newItem.order = this.data.items.length + 1;
    this.data.items.push(newItem);

    setTimeout(() => {
      this.generalUtils.scrollToElement(newItem.id);
    }, 0);
  };

  handleDeleteItem = (idx: number) => {
    this.data.items.splice(idx, 1);
  };

  populateDataFromDefualtPOCCache = () => {
      if (this.defaultPOCCache === null) {
        return this.toaster.pop("warning", "Failed to populate items from default POC");
      }

      this.categories = this.defaultPOCCache.sections.map((section, index) => ({ id: index, label: section }));
      this.data.items = this.defaultPOCCache.items.map((i, idx) => ({ ...i, state: "VALID", id: `${ITEM_ID_PREFIX}-${idx}` }));

      setTimeout(() => {
        this.generalUtils.scrollToElement(this.data.items[this.data.items.length - 1].id);
      }, 200);
  };

  handleClickUseDefaultPOC = () => {
    if (this.defaultPOCCache !== null) {
      this.populateDataFromDefualtPOCCache();
    }
    this.isLoadingDefaultPOC = true;
    this.planOfCareService
      .getDefaultPOCTaskItems()
      .then((data) => {
        this.defaultPOCCache = data;
        this.populateDataFromDefualtPOCCache();
      }).finally(() => {
        this.isLoadingDefaultPOC = false;
      });
  };

  isValidPOC = (): boolean => {
    for (const field of this.dataValidation.fields) {
      switch (field.name) {
        case "OFFICES": {
          field.valid = this.data.officeIds.length > 0;
          break;
        }
        case "ITEMS": {
          field.errorMessages = [];
          const itemsLength = this.data.items.length;
          if (itemsLength === 0) {
            field.valid = false;
            break;
          }

          const allItemsAreValid = this.data.items.every((i) => i.state === "VALID");
          field.valid = allItemsAreValid
          if (!allItemsAreValid) {
            field.errorMessages.push(TasksErrorTypes.INCOMPLETE);
            break;
          }

          // CHECK FOR UNQIUE CODES
          const codes = this.data.items.map((i) => i.code);
          if (this.checkIfDuplicateExists(codes)) {
            field.valid = false;
            field.errorMessages.push(TasksErrorTypes.UNIQUE_CODES);
          }

          // CHECK FOR UNQIUE ORDER NUMBERS
          const orderNumbers = this.data.items.map((i) => i.order);
          if (this.checkIfDuplicateExists(orderNumbers)) {
            field.valid = false;
            field.errorMessages.push(TasksErrorTypes.ORDER);
          }
          break;
        }
      }
    }

    return this.dataValidation.fields.every((field) => field.valid);
  };

  submitPOCType = () => {
    this.data.items.forEach((i) => (i.code = i.code.toString()));
    this.isSubmitting = true;
    this.planOfCareService
      .createCustomPlanOfCareType(this.data)
      .then(() => {
        this.toaster.success("Custom plan of care created successfully");
        this.resolve.handleSuccess();
        this.resolve.closeModal();
      })
      .catch((error) => {
          this.toaster.error(
            this.apiErrors.format(
              error,
              "Failed to create custom plan of care for the selected offices"
            )
          );
      })
      .finally(() => {
        this.isSubmitting = false;
      });
  };

  handleClickCreatePOC = () => {
    if (this.isValidPOC()) {
      this.submitPOCType();
    } else {
      const errors = this.dataValidation.fields.flatMap((field) =>
        field.valid ? [] : field.errorMessages.map((m) => `* ${m}`)
      );
      const errorsList = errors.join("\n");
      const modal = this.mfModal.create({
        subject: "POC Validation",
        message: errorsList,
        variant: "info",
        hideCancelButton: true,
        confirmLabel: "OK",
        onConfirm: () => {
          modal.close();
        },
      });
    }
  };

  handleClickCancelPOC = () => {
    const modal = this.mfModal.create({
      subject: "Are you sure you want to cancel this POC?",
      message: "All the information you have entered will be lost",
      variant: "info",
      confirmLabel: "OK",
      onConfirm: () => {
        modal.close();
        if (this.resolve.closeModal) {
          this.resolve.closeModal();
        }
      },
    });
  };

  checkIfDuplicateExists(arr: Array<string | number>) {
    return new Set(arr).size !== arr.length;
  }
}

export const customPlanOfCareTypeComponent: CustomPlanOfCareTypeCtrlComponentOptions = {
  $name: "customPlanOfCareType",
  templateUrl: "admin/components/custom-plan-of-care-type/custom-plan-of-care-type.component.html",
  controller: CustomPlanOfCareTypeCtrl,
  controllerAs: "ctrl",
  bindings: {
    resolve: "<",
  },
};
