import { LocalDate, LocalDateTime, DateTimeFormatter } from "js-joda";
import moment from "moment";

export const caregiverCalendar = {
  templateUrl: "admin/views/caregiver-calendar.html",
  bindings: {
    caregiver: "<",
    onClickNote: "&",
    onCreateCaregiverAbsence: '&',
    onClickDeleteAbsences: "&",
    showDeletedVisits: '<',
    extraColumns: '<'
  },
  //! @ngInject
  controller: function (
    $rootScope,
    $scope,
    $uibModal,
    $filter,
    DatabaseApi,
    CalendarItemType,
    mfModal,
    visitInstanceService,
    dateUtils,
    generalUtils,
  ) {

    this.calendarType = "CAREGIVER";
    this.patientsMap = DatabaseApi.patients();
    this.isAllowItemsCheck = true;
    this.multipleDaysSelection = false;
    this.serviceCodesMap = DatabaseApi.serviceCodes();

    $scope.initializeMap = DatabaseApi.entitiesInitializeMap();

    $scope.$on("got_patients_data", () => {
      $scope.initializeMap = DatabaseApi.entitiesInitializeMap();
      this.patientsMap = DatabaseApi.patients();
      this.fetchData(this.state.dates);
    });

    this.getInitialStateEntity = () => {
      return {
        data: [],
        isLoading: false,
        error: undefined,
      };
    };

    this.state = {
      items: this.getInitialStateEntity(),
      events: this.getInitialStateEntity(),
      dates: undefined,
      startDayOfWeek: $rootScope.visitSettings.calendarStartDayOfTheWeek
        ? parseInt($rootScope.visitSettings.calendarStartDayOfTheWeek)
        : 0,
    };

    this.actions = [
      {
        label: "New Note",
        invoke: (selection) => this.handleClickNewNote(selection),
      },
      {
        label: "Set In PTO",
        invoke: (selection) => this.handleClickSetInPto(selection),
      },
      {
        label: "Set In Absence",
        invoke: (selection) => this.handleClickSetInAbsence(selection),
        permissionKey: "create/delete_caregiver_absence",
        isHidden: (selection) => this.isSelectionContainingAbsence(selection)
      },
      {
        label: "Delete Absence",
        invoke: (selection) => this.handleClickDeleteAbsence(selection),
        permissionKey: 'create/delete_caregiver_absence',
        isHidden: (selection) => !this.isSelectionContainingAbsence(selection)
      },
    ];

    this.ptoLabels = DatabaseApi.caregiverPtoLabels() || [];

    this.mapCaregiverNotesToMfCalendarDayEvent = ({
      notes,
      onClickFactory,
    }) => {
      const notesPerDayMap = new Map();
      const toReturn = [];

      for (const note of notes) {
        const totalNotesInDay = notesPerDayMap.get(note.calendarDate) || 0;

        notesPerDayMap.set(note.calendarDate, totalNotesInDay + 1);
      }

      for (const [date, totalNotes] of notesPerDayMap.entries()) {
        toReturn.push({
          type: "NOTE",
          date: LocalDate.parse(date),
          onClick: onClickFactory(date),
          payload: {
            total: totalNotes,
          },
        });
      }

      return toReturn;
    };

    this.setItemsData = () => {      
      const visitInstances = calculateOverlappingVisits(this.filterVisitInstances().map(this.mapVisitInstanceToMfCalendarItem)).sort(this.sortVisitInstances);
      const ptoInstances = $scope.itemsAndEventsData?.ptoInstances.caregiverPtos.map(
        this.mapPtoInstanceToMfCalendarItem
      ) ?? [];
      const absences = $scope.itemsAndEventsData?.absences.map(
        this.mapAbsenceToMfCalendarItem
      ) ?? [];
      this.state.items = {
        data: [
          ...visitInstances,
          ...ptoInstances,
          ...absences,
        ],
        isLoading: false,
      };
    }

    const calculateOverlappingVisits = (visitInstances) => {
      visitInstances.sort((visitA, visitB) => {
        const visitAClockInTime = visitA.payload.clockinTime !== null ? LocalDateTime.parse(visitA.payload.clockinTime) : LocalDateTime.MAX;
        const visitBClockInTime = visitB.payload.clockinTime !== null ? LocalDateTime.parse(visitB.payload.clockinTime) : LocalDateTime.MAX;
        
        return visitAClockInTime.compareTo(visitBClockInTime);   
      });

      for (let i = 1; i < visitInstances.length; i++) {
        const areOverlapping = visitInstances[i - 1].payload.clockoutTime > visitInstances[i].payload.clockinTime;
        if (
          areOverlapping && (
            isOverlappingPermittedByServiceCode(visitInstances[i - 1]) ||
            isOverlappingPermittedByServiceCode(visitInstances[i])
          )
        ) {
          visitInstances[i].payload.isClockInOverlap = true;
          visitInstances[i - 1].payload.isClockOutOverlap = true;
        }
      }

      return visitInstances;
    }

    const isOverlappingPermittedByServiceCode = (visitInstance) => {
      const serviceCodeId = visitInstance.payload.__original.visit.serviceCodeId;

      if (!serviceCodeId) {
        return false;  
      }
      
      const serviceCode = this.serviceCodesMap.find(item => item.id === serviceCodeId);
      return serviceCode?.allowVisitOverlap ?? false;
    }

    this.fetchData = async () => {
      if (!$scope.initializeMap["serviceCodes"] || !$scope.initializeMap["patients"]) {
        return;
      }

    this.state.items = { ...this.getInitialStateEntity(), isLoading: true };
    this.state.events = { ...this.getInitialStateEntity(), isLoading: true };

    $scope.issuesSettings = await DatabaseApi.agencyBillingIssues();

    let url = "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/visit_instances_billing"
      .replace(":agencyId", $rootScope.agencyId)
      .replace(":agencyMemberId", $rootScope.agencyMemberId)
      .replace(":caregiverId", this.caregiver.id);

      if (this.state.dates) {
        const fromDate = this.state.dates.from.minusDays(1);
        const toDate = this.state.dates.to.plusDays(1);
        url += "?from=:from&to=:to"
          .replace(":from", fromDate)
          .replace(":to", toDate);
      }

    return DatabaseApi.get(url)
      .then(({ data }) => {
        $scope.itemsAndEventsData = data;
        this.setItemsData();

        this.state.events = {
          original: data.notes,
          data: [
            ...this.mapCaregiverNotesToMfCalendarDayEvent({
              notes: data.notes,
              onClickFactory: (date) => () =>
                this.handleClickCaregiverNoteEvent(date),
            }),
          ],
          isLoading: false,
        };
      })
      .catch((e) => {
        console.error(e);
        this.state.items.error = e.message;
        this.state.items.isLoading = false;
        this.state.events.isLoading = false;
      });
    };

    this.handleChangeView = ({ from, to }) => {
      if (
        this.state.dates &&
        this.state.dates.from.equals(from) &&
        this.state.dates.to.equals(to)
      ) {
        return; // do nothing
      }
      this.state.dates = { from, to };

      this.fetchData(this.state.dates);
    };


    this.handleClickNewNote = ({ day }) => {
      const calendarDate = day.date;

      const newScope = $scope.$new();
      newScope.note = {
        caregiverId: this.caregiver.id,
        calendarDate: calendarDate
      };

      $rootScope.openNewCalendarNoteModal(newScope).then((result) => {
        if (result === "OK") {
          this.fetchData();
          generalUtils.scrollToElement('scroll-calendar');
        }
      });
    };

    this.handleClickSetInAbsence = ({ day }) => {
      const calendarDate = day.date;
      $scope.$ctrl.onCreateCaregiverAbsence()(calendarDate).then((res) => {
        if (res === "ABSENCE_CREATED") {
          this.fetchData();
          generalUtils.scrollToElement('scroll-calendar');
          $rootScope.$broadcast("refresh_ptos");
        }
      });
    };

    this.handleClickDeleteAbsence = ({ day }) => {
      const absenceToDelete = day.items.find(item => item.type === CalendarItemType.CAREGIVER_ABSENCE);
      if (absenceToDelete === undefined) {
        mfModal.createSimple({
          subject: "Error",
          message: "No caregiver absence found on the chosen date",
          variant: "danger",
        });
      }
      if ($scope.$ctrl.onClickDeleteAbsences()) {
        $scope.$ctrl.onClickDeleteAbsences()({ selectedAbsences: [absenceToDelete] }, 'scroll-calendar');
      }
    };

    this.handleClickSetInPto = ({ items, total }) => {
      const ptoAbleItems = items.filter(
        (x) => [
          CalendarItemType.ASSIGNED_VISIT,
          CalendarItemType.MISSED_VISIT
        ].includes(x.type)
      );

      if (this.caregiver.hireDate === null) {
        const modal = mfModal.create({
          subject: "Oops!",
          message: `Caregiver must have hire date in order to add paid time off.`,
          hideCancelButton: true,
          confirmLabel: "OK",
          onConfirm: () => modal.close(),
        });
        return;
      }

      if (ptoAbleItems.length === 0) {
        const rangeText = () => (total === 1 ? "day" : "range of days");
        const modal = mfModal.create({
          subject: "Oops!",
          variant: "danger",
          message: `There are no assigned visits in the selected ${rangeText()}.`,
          hideCancelButton: true,
          confirmLabel: "OK",
          onConfirm: () => modal.close(),
        });
        return;
      }

      const newScope = $scope.$new();

      newScope.caregiverId = this.caregiver.id;
      newScope.visitInstances = ptoAbleItems.map((x) => {
        const visitInstance = x.payload.__original.visit;

        return {
          ...visitInstance,
          originalStart: moment(visitInstance.startTime),
          originalEnd: moment(visitInstance.endTime),
        };
      });

      const modalInstance = $uibModal.open({
        templateUrl: "admin/views/visit-instance-pto-modal.html",
        size: "md",
        controller: "visitInstancePTOModalCtrl",
        windowTopClass: "PTO-modal",
        scope: newScope,
      });

      modalInstance.result.then((res) => {
        if (res === "CREATE_SUCCESS") {
          this.fetchData();
          generalUtils.scrollToElement('scroll-calendar');
          $rootScope.$broadcast("refresh_ptos");
        }
      });
    };

    this.openPtoItemModal = ({
      ptoLabel,
      startTime,
      endTime,
      patientId,
      __original,
    }) => {
      const newScope = $scope.$new();
      const patient = this.patientsMap[patientId];

      newScope.caregiverId = this.caregiver.id;
      newScope.ptoEventForm = __original;
      newScope.ptoEventForm.pto = __original;
      newScope.ptoEventForm.label = ptoLabel;
      newScope.ptoEventForm.visitInstance.originalStart = moment(startTime.toJSON ? startTime.toJSON() : startTime);
      newScope.ptoEventForm.visitInstance.originalEnd = moment(endTime.toJSON ? endTime.toJSON() : endTime);
      newScope.ptoEventForm.visitInstance.titleName =
        patient !== undefined ? patient.displayName : "";
      newScope.visitInstances = [newScope.ptoEventForm.visitInstance];
      newScope.viewModeOnly = true;

      const modalInstance = $uibModal.open({
        templateUrl: "admin/views/visit-instance-pto-modal.html",
        size: "md",
        controller: "visitInstancePTOModalCtrl",
        windowTopClass: "PTO-modal",
        scope: newScope,
      });

      modalInstance.result.then((res) => {
        if (res === "EDIT_SUCCESS" || res === "REMOVE_SUCCESS") {
          this.fetchData();
          generalUtils.scrollToElement('scroll-calendar');
          $rootScope.$broadcast("refresh_ptos");
        }
      });
    };

    this.handleClickItem = (item) => {
      switch (item.type) {
        case CalendarItemType.UNSTAFFED_VISIT:
        case CalendarItemType.DELETED_VISIT:
        case CalendarItemType.MISSED_VISIT:
        case CalendarItemType.BROADCASTED_VISIT:
        case CalendarItemType.ASSIGNED_VISIT:
          $rootScope.openVisitInstanceModal(item.payload.visitInstanceId);
          return;
        case CalendarItemType.PAID_TIME_OFF:
          this.openPtoItemModal(item.payload);
          return;
        case CalendarItemType.CAREGIVER_ABSENCE:
          // do nothing
          return;
        default:
          console.log(
            `There's no case for handling item of type "${item.type}"`
          );
      }
    };

    this.handleClickCaregiverNoteEvent = (date) => {
      if (this.onClickNote()) {
        this.onClickNote()({ date });
      }
    };

    this.getMfCalendarItemTypeByVisitInstance = ({
      caregiverId,
      missedVisit,
      removedAt,
      ptoInstance,
      visitBroadcast,
    }) => {
      // DELETED_VISIT
      if (removedAt) {
        return CalendarItemType.DELETED_VISIT;
      }

      // PAID_TIME_OFF
      if (ptoInstance) {
        return CalendarItemType.PAID_TIME_OFF;
      }

      // MISSED_VISIT
      if (missedVisit) {
        return CalendarItemType.MISSED_VISIT;
      }

      // BROADCASTED_VISIT
      if (visitBroadcast !== null) {
        return CalendarItemType.BROADCASTED_VISIT;
      }

      // UNSTAFFED_VISIT
      if (caregiverId === null) {
        return CalendarItemType.UNSTAFFED_VISIT;
      }

      // ASSIGNED_VISIT
      return CalendarItemType.ASSIGNED_VISIT;
    };

    this.getMfCalendarItemBillingStatusByVisitInstance = ({
      missingAuth,
      issue_authorization_over_allocation,
      issue_authorization_under_allocation,
      issue_invalid_authorization_limits_total,
      issue_invalid_authorization_assignment
    }) => {
      if (missingAuth || issue_invalid_authorization_assignment) {
        return {
          message: "No Authorization"
        };
      }

      if (issue_authorization_over_allocation) {
        return {
          message: "Auth Over Allocation",
          tooltip: issue_authorization_over_allocation
        };
      }

      if (issue_authorization_under_allocation) {
        return {
          message: "Auth Under Allocation",
          tooltip: issue_authorization_under_allocation
        };
      }

      if (issue_invalid_authorization_limits_total) {
        return {
          message: "Auth Over Utilized",
          tooltip: issue_invalid_authorization_limits_total
        };
      }

      return null;
    }


    this.getPtoStatusLabel = (status) => {
      switch (status) {
        case "APPROVED":
          return "Approved";
        case "PENDING":
          return "Pending";
        case "DECLINED":
          return "Declined";
        case false:
          return null;
      }
    };

    this.mapPtoInstanceToMfCalendarItem = (caregiverPto) => {
      const endTime = LocalDateTime.parse(
        caregiverPto.visitInstance.startTime
      ).plusMinutes(caregiverPto.duration);

      return {
        key: `pto-${caregiverPto.id}`,
        checked: false,
        date: LocalDateTime.parse(
          caregiverPto.visitInstance.startTime
        ).toLocalDate(),
        type: CalendarItemType.PAID_TIME_OFF,
        options: {
          onClickItem: (item) => this.handleClickItem(item),
        },
        payload: {
          __original: caregiverPto,
          visitInstanceId: caregiverPto.visitInstance.id,
          startTime: caregiverPto.visitInstance.startTime,
          endTime: endTime,
          ptoLabel: this.ptoLabels.find((x) => x.id === caregiverPto.labelId),
          formattedStatus: this.getPtoStatusLabel(caregiverPto.status),
          patientId: caregiverPto.visitInstance.patientId,
          paidLabel:
            caregiverPto.paidSeconds
              ? $filter("duration")([0, caregiverPto.paidSeconds * 1000])
              : null,
        },
      };
    };

    this.mapVisitInstanceToMfCalendarItem = (visitInstance) => {
      const type = this.getMfCalendarItemTypeByVisitInstance({
        caregiverId: visitInstance.visit.caregiverId,
        missedVisit: visitInstance.visit.missedVisit,
        removedAt: visitInstance.visit.removedAt,
        visitBroadcast: visitInstance.visitBroadcast,
      });

      const broadcastStatus = visitInstanceService.getVisitInstanceBroadcastStatus(visitInstance);
      const visitInstanceIssues = visitInstanceService.getVisitInstanceIssues(visitInstance, $scope.issuesSettings);

      return {
        key: `visit-${visitInstance.visit.id}`,
        checked: false,
        date: LocalDate.parse(visitInstance.visitDate),
        type: type,
        options: {
          allowNavigateToPatient: true,
        },
        payload: {
          __original: visitInstance,
          broadcastStatus: broadcastStatus,
          isOnWeeklyTemplate: visitInstance.isOnWeeklyTemplate,
          billingStatus: this.getMfCalendarItemBillingStatusByVisitInstance(
            visitInstance
          ),
          billedLabel:
            visitInstance.billedSeconds === 0
              ? null
              : $filter("duration")([0, visitInstance.billedSeconds * 1000]),
          paidLabel:
            visitInstance.paidSeconds === 0
              ? null
              : $filter("duration")([0, visitInstance.paidSeconds * 1000]),
          payrollDraftLabel:
            visitInstance.draftPaidSeconds === 0
              ? null
              : $filter("duration")([0, visitInstance.draftPaidSeconds * 1000]),
          visitInstanceId: visitInstance.visit.id,
          visitBatchType: visitInstance.visitBatchType,
          caregiverId: visitInstance.visit.caregiverId,
          patientId: visitInstance.visit.patientId,
          patientName: visitInstance.visit.patientName,
          startTime: visitInstance.visit.startTime,
          endTime: visitInstance.visit.endTime,
          clockinTime: visitInstance.visit.clockinTime,
          clockoutTime: visitInstance.visit.clockoutTime,
          issues: visitInstanceIssues,
        },
      };
    };

    this.mapAbsenceToMfCalendarItem = (caregiverAbsence) => {
      return {
        key: `absence-${caregiverAbsence.id}`,
        checked: false,
        date: dateUtils.ymdStringToLocalDate(caregiverAbsence.date),
        type: CalendarItemType.CAREGIVER_ABSENCE,
        options: {
          onClickItem: (item) => this.handleClickItem(item),
        },
        payload: {
          __original: caregiverAbsence,
          noteId: caregiverAbsence.noteId,
          noteRichText: caregiverAbsence.noteRichText,
        },
      };
    };

    const caregiverCalendarFilterByMethods = {
      visitInstanceIsDeleted: (visit) => visit.removedAt === null
    }

    this.filterVisitInstances = () => {
      if (!$scope.itemsAndEventsData) return [];
      const filters = [];

      if($scope.$ctrl.showDeletedVisits === false) {
        filters.push(visitInstance => caregiverCalendarFilterByMethods.visitInstanceIsDeleted(visitInstance.visit));
      }

      let filtereVisitInstances = $scope.itemsAndEventsData.visitInstances;
      if (filters.length > 0) {
        filtereVisitInstances = filtereVisitInstances.filter(function (visit) {
          let isFiltered = true;
          for (let idx = 0; isFiltered && idx < filters.length; idx++) {
            isFiltered = isFiltered && filters[idx](visit);
          }
          return isFiltered;
        });
      }

      return filtereVisitInstances;
    };

    this.sortVisitInstances = (a, b) => {
      if (
        (a.payload.__original.visit.isVisitDateOnDayBefore || b.payload.__original.visit.isVisitDateOnDayBefore) &&
        a.payload.__original.visit.isVisitDateOnDayBefore !== b.payload.__original.visit.isVisitDateOnDayBefore
      ) {
        return a.payload.__original.visit.isVisitDateOnDayBefore ? 1 : -1;
      }

      return LocalDateTime.parse(a.payload.startTime).compareTo(
        LocalDateTime.parse(b.payload.startTime)
      );
    };

    this.handleMultipleSetInPto = () => {
      let items = [];
      let datesSet = new Set();
      const formatter = DateTimeFormatter.ofPattern("MM/dd/yyyy");
      
      this.state.items.data.forEach(item => {
        if (item.type !== CalendarItemType.PAID_TIME_OFF && item.checked) {
          items.push(item);
          datesSet.add(item.date.format(formatter));
        }
      });
      this.handleClickSetInPto({ items, total: datesSet.size });
    };

    this.handleClickDeleteAbsences = () => {
      const selectedCount = this.state.items.data.filter(item => item.checked).length;
      const selectedAbsences = this.state.items.data.filter(item =>
        item.type === CalendarItemType.CAREGIVER_ABSENCE
        && item.checked
      );
      if (selectedAbsences.length === 0 || selectedAbsences.length !== selectedCount) {
        mfModal.createSimple({
          subject: "Error",
          message: "Please select only caregiver absences",
          variant: "danger",
        });
      }
      if ($scope.$ctrl.onClickDeleteAbsences()) {
        $scope.$ctrl.onClickDeleteAbsences()({ selectedAbsences: selectedAbsences }, 'scroll-calendar');
      }
    };

    this.itemsActions = [];
    if ($rootScope.isPermittedByKey("view_caregivers_section_pto_approvals")) {
      this.itemsActions.push({
        text: "Set PTO",
        variant: "primary",
        callback: this.handleMultipleSetInPto,
        isDisabled: true
      });
    }

    if ($rootScope.isPermittedByKey("create/delete_caregiver_absence")) {
      this.itemsActions.push({
        text: "Delete Absence",
        variant: "danger",
        callback: this.handleClickDeleteAbsences,
        isDisabled: true
      });
    }

    this.updateItemsActionsDisables = () => {
      const selectedCount = this.state.items.data.filter(item => item.checked).length;
      const selectedPtoAbleItems = this.state.items.data.filter(item =>
        [
          CalendarItemType.ASSIGNED_VISIT,
          CalendarItemType.MISSED_VISIT
        ].includes(item.type) &&
        item.checked
      );
      const selectedAbsences = this.state.items.data.filter(item =>
        item.type === CalendarItemType.CAREGIVER_ABSENCE
        && item.checked
      );

      this.itemsActions.forEach(action => {
        switch (action.text) {
          case "Set PTO":
            action.isDisabled = (
              selectedPtoAbleItems.length === 0 ||
              selectedPtoAbleItems.length !== selectedCount
            );
            break;
          case "Delete Absence":
            action.isDisabled = (
              selectedAbsences.length === 0 ||
              selectedAbsences.length !== selectedCount
            );
            break;
          default:
            break;
        }
      });
    };

    this.isSelectionContainingAbsence = ({ val }) => {
      return val.day.items.find(item => item.type === CalendarItemType.CAREGIVER_ABSENCE) !== undefined;
    };

    this.handleCheckSingleItem = (item) => {
      if (!item.key) {
        return;
      }

      if (item.type !== CalendarItemType.PAID_TIME_OFF) {
        item.checked = !item.checked;
      }

      this.updateItemsActionsDisables();
    };

    $rootScope.$on("refresh_visits", () => this.fetchData(this.state.dates));

    this.$onChanges = (changedData) => {
      const previousValue = changedData.showDeletedVisits && changedData.showDeletedVisits.previousValue;

      if (!changedData || this.state.items.isLoading ||
        (typeof previousValue === 'object' && Object.keys(previousValue).length === 0)) {
        return;
      }
      this.setItemsData();
    }
  }
};
